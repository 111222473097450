body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
}
a {
  color: inherit;
  text-decoration: none;
}

label + .MuiInput-formControl {
  margin-top: 0 !important;
}

@font-face {
  font-family: 'Söhne Breit Fett';
  font-display: fallback;
  font-style: normal;
  src: 
    url(./assets/fonts/SöhneBreitTest-Fett.woff) format('woff');
}

@font-face {
  font-family: 'Söhne Breit Buch';
  font-display: fallback;
  font-style: normal;
  src: 
    url(./assets/fonts/soehne-breit-web-buch.woff) format('woff'),
    url(./assets/fonts/soehne-breit-web-buch.woff2) format('woff2'),
    url(./assets/fonts/soehne-breit-web-buch.eot) format('eot');
}
